import React, { useMemo, useState } from 'react';
import cc from 'classcat';
import { RemoveScroll } from 'react-remove-scroll';
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStatus,
} from '@floating-ui/react';

import Button from '@/components/Button';
import { IconX } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

export interface DialogOptions {
  /** Open dialog on intial render */
  initialOpen?: boolean;
  /** If provided, will make component controlled, use onOpenChange() to get latest state updates from component */
  open?: boolean;
  /** If provided, will make component controlled, open should be set here  */
  onOpenChange?: (open: boolean) => void;
  unlockScroll?: boolean;
  /** If provided, will change the size of the modal.
   * small 328px width, medium 480px max-width, large 900px max-width, fullscreen modal dimensions stretch to full screen
   */
  size?: 'small' | 'medium' | 'large' | 'fullscreen';
}

/**
 * Internal hook to get memoized DialogOptions from context
 */
const useDialog = ({
  initialOpen = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  unlockScroll,
  size = 'medium',
}: DialogOptions = {}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    open,
    onOpenChange: setOpen,
  });

  const context = data.context;

  const click = useClick(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
      unlockScroll,
      size,
    }),
    [open, setOpen, interactions, data, labelId, descriptionId, unlockScroll, size],
  );
};

type ContextType =
  | (ReturnType<typeof useDialog> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
      setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>;
    })
  | null;
const DialogContext = React.createContext<ContextType>(null);

/**
 * Internal hook to get Dialog Context, throws error if user attempts to use child component ouside of <Dialog/>
 */
const useDialogContext = () => {
  const context = React.useContext(DialogContext);

  if (context === null) {
    throw new Error('Dialog components must be wrapped in <Dialog />');
  }

  return context;
};

export const Dialog = ({
  children,
  ...options
}: {
  children: React.ReactNode;
} & DialogOptions) => {
  const dialog = useDialog(options);
  return <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>;
};

interface DialogTriggerProps {
  children: React.ReactNode;
  asChild?: boolean;
}

/**
 * Trigger for Dialog component. By default will wrap text into a `<Button/>`.
 *  Also attaches `data-sate` (`'open'` | `'closed'`) for custom styling
 * @param asChild Allows any passed element as the anchor (set if using custom component)
 */
export const DialogTrigger = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & DialogTriggerProps>(
  function DialogTrigger({ children, asChild = false, ...props }, propRef) {
    const context = useDialogContext();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows any passed element as the anchor
    if (asChild && React.isValidElement(children)) {
      return React.cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        }),
      );
    }

    return (
      <Button
        ref={ref}
        size="medium"
        data-state={context.open ? 'open' : 'closed'}
        {...context.getReferenceProps(props)}
      >
        {children}
      </Button>
    );
  },
);

/**
 * Dialog Element; wraps any children (React nodes). Should wrap helper components:
 *  - `<DialogHeading/>`
 * - `<DialogBody/>`
 * - `<DialogClose/>`
 */
export const DialogContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function DialogContent(
  props,
  propRef,
) {
  const { context: floatingContext, size, ...context } = useDialogContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  const { isMounted, status } = useTransitionStatus(floatingContext, {
    // NOTE: if you adjust this; make sure to change the related transition-duraction css
    duration: 250,
  });

  if (!isMounted) return null;

  const classNames = cc({
    [styles.dialog]: true,
    [styles[size]]: styles[size],
  });

  return (
    <FloatingPortal>
      <FloatingOverlay className={styles.dialogOverlay} data-state={status}>
        <FloatingFocusManager context={floatingContext}>
          <RemoveScroll
            ref={ref}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            className={classNames}
            data-testid="dialog"
            {...context.getFloatingProps(props)}
            enabled={!context.unlockScroll}
          >
            {props.children}
          </RemoveScroll>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
});

interface DialogHeadingProps {
  /** display close icon; defaults to true */
  showCloseIcon?: boolean;
}
/**
 * DialogContent child component, wraps heading and applies aria-label.
 * @param children React Node children to be wrapped within `<h2>`
 */
export const DialogHeading = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement> & DialogHeadingProps
>(function DialogHeading({ children, showCloseIcon = true, ...props }, ref) {
  const { setLabelId, setOpen } = useDialogContext();
  const id = useId();

  // Only sets `aria-labelledby` on the Dialog root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setLabelId(id);
    return () => setLabelId(undefined);
  }, [id, setLabelId]);

  return (
    <div className={styles.heading}>
      <h2 {...props} ref={ref} id={id}>
        {children}
      </h2>
      {showCloseIcon && (
        <button
          aria-label="close dialog"
          data-testid="dialog-close"
          className={styles.closeButton}
          type="button"
          onClick={() => setOpen(false)}
        >
          <IconX />
        </button>
      )}
    </div>
  );
});

/**
 * DialogContent child component, wraps content and applies aria-describedby.
 * @param children React Node children to be wrapped within `<p>`
 */
export const DialogBody = React.forwardRef<HTMLParagraphElement, React.HTMLProps<HTMLParagraphElement>>(
  function DialogBody({ children, ...props }, ref) {
    const { setDescriptionId } = useDialogContext();
    const id = useId();

    // Only sets `aria-describedby` on the Dialog root element
    // if this component is mounted inside it.
    React.useLayoutEffect(() => {
      setDescriptionId(id);
      return () => setDescriptionId(undefined);
    }, [id, setDescriptionId]);

    return (
      <p {...props} ref={ref} id={id}>
        {children}
      </p>
    );
  },
);

interface DialogActionProps {
  /** close dialog after onClick(); default false  */
  noCloseAfterOnClick?: boolean;
}

/**
 * DialogContent child component, wraps content in `<Button/>` and provides helper props.
 * @param noCloseAfterOnClick Close dialog after onClick()
 */
export const DialogButton = React.forwardRef<
  HTMLButtonElement,
  MakeOptional<ButtonPropsWithBase<ButtonButtonProps>, 'onClick'> & DialogActionProps
>(function DialogAction({ noCloseAfterOnClick, ...buttonProps }, ref) {
  const { setOpen } = useDialogContext();
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    buttonProps.onClick && buttonProps.onClick(e);
    !noCloseAfterOnClick && setOpen(false);
  };

  return (
    <Button ref={ref} as="button" size="small" {...buttonProps} onClick={handleOnClick}>
      {buttonProps.children}
    </Button>
  );
});

/**
 * DialogContent child component, simple wrapper that sets actions into flex grid.
 */
export const DialogActions = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function DialogActions(
  props,
  ref,
) {
  return (
    <div className={styles.dialogActions} ref={ref} {...props}>
      {props.children}
    </div>
  );
});
