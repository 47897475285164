import React, { useMemo } from 'react';
import cc from 'classcat';

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/Tooltip';
import { IconInfoCircle } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

type FieldLabelTextProps = {
  text: string;
  required?: boolean;
  tooltipContent?: string | (() => JSX.Element);
  // defaults
  /** component to set as root node; defaults to `p` */
  component?: 'p' | 'legend';
};

const FieldLabelText = ({ text, required, tooltipContent, component: RootNode = 'p' }: FieldLabelTextProps) => {
  const labelTooltip = useMemo(() => {
    if (!tooltipContent) return null;
    return (
      <>
        <div style={{ visibility: 'hidden' }}>
          <IconInfoCircle width={16} height={16} />
        </div>
        <Tooltip>
          <TooltipTrigger className={styles.tooltip}>
            <IconInfoCircle width={16} height={16} />
          </TooltipTrigger>
          <TooltipContent>
            {typeof tooltipContent === 'function' ? tooltipContent() : <p>{tooltipContent}</p>}
          </TooltipContent>
        </Tooltip>
      </>
    );
  }, [tooltipContent]);

  return (
    <div className={styles.fieldLabel}>
      <RootNode className={cc([styles.fieldLabelText, required && styles.required])}>{text}</RootNode>
      {labelTooltip}
    </div>
  );
};

export default FieldLabelText;
