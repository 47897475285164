import React, { useId, useMemo } from 'react';
import cc from 'classcat';

import FieldHelperText from '../components/FieldHelperText';
import FieldLabelText from '../components/FieldLabelText';
import FieldValidationText from '../components/FieldValidationText';

import styles from './styles.module.scss';

type InputProps = {
  name: string;
  /** show action-lightest background color default false */
  autoFilled?: boolean;
  /** show disabled background color but text + icon colour is foreground muted; default false */
  prefilledDisabled?: boolean;
  /** override to keep focused styles visible; default false */
  isFocused?: boolean;
} & FieldComponentPropsWithIcons<string, 'input'>;

type TextAreaProps = {
  name: string;
} & FieldComponentPropsWithIcons<string, 'textarea'>;

export type TextFieldProps = (InputProps & TextAreaProps) &
  (
    | {
        as?: 'input';
      }
    | {
        as: 'textarea';
      }
  );

const TextField = (
  {
    name,
    value,
    onChange,
    disabled,
    helperText,
    severity,
    label,
    placeholder,
    required,
    readOnly,
    validationText,
    IconLeft,
    IconRight,
    type = 'text',
    as,
    autoFilled = false,
    prefilledDisabled = false,
    id: htmlId,
    tooltipContent,
    isFocused,
    ...htmlInputProps
  }: TextFieldProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const id = useId();

  const classNames = useMemo(() => {
    let classes = cc({
      [styles.textField]: true,
      [styles.disabled]: disabled,
      [styles.autoFilled]: autoFilled,
      [styles.prefilledDisabled]: prefilledDisabled,
      [styles.isFocused]: isFocused,
    });

    if (severity && styles[severity]) {
      classes = cc([classes, styles[severity]]);
    }

    return classes;
  }, [disabled, severity, autoFilled, prefilledDisabled, isFocused]);

  const InputComponent = useMemo(() => as ?? 'input', [as]);
  return (
    <label htmlFor={htmlId ?? id} aria-label={label ?? name} className={classNames}>
      {!!label && <FieldLabelText text={label} required={required} tooltipContent={tooltipContent} />}
      {!!helperText && <FieldHelperText text={helperText} />}
      <div ref={ref} className={cc([styles.input, readOnly && styles.readOnly])}>
        {IconLeft && (
          <div className={styles.icon}>
            <IconLeft />
          </div>
        )}
        <InputComponent
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          id={htmlId ?? id}
          {...htmlInputProps}
        />
        {IconRight && (
          <div className={styles.icon}>
            <IconRight />
          </div>
        )}
      </div>
      {!!validationText && <FieldValidationText text={validationText} severity={severity} />}
    </label>
  );
};

export default React.forwardRef(TextField);
