import React from 'react';

import styles from './styles.module.scss';

interface FieldHelperTextProps {
  text: string;
}

const FieldHelperText = ({ text }: FieldHelperTextProps) => {
  return <p className={styles.fieldHelperText}>{text}</p>;
};

export default FieldHelperText;
