import React from 'react';
import cc from 'classcat';

import { IconInfoCircle } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

interface FieldValidationTextProps {
  text: string;
  severity?: FieldSeverity;
}

const FieldValidationText = ({ text, severity }: FieldValidationTextProps) => {
  return (
    <div data-testid="field-validation" className={cc([styles.fieldValidationText, severity && styles[severity]])}>
      <IconInfoCircle width={24} height={24} />
      <p>{text}</p>
    </div>
  );
};

export default FieldValidationText;
