import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import cc from 'classcat';
import {
  FloatingFocusManager,
  FloatingList,
  autoUpdate,
  flip,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListItem,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react';

import { IconChevronDown } from '@/images/icons/tabler-icons';

import TextField from '../TextField';

import styles from './styles.module.scss';

// Context
interface SelectContextValue {
  activeIndex: number | null;
  selectedIndex: number | null;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  handleSelect: (index: number | null, value?: any) => void;
}

const SelectContext = React.createContext<SelectContextValue>({} as SelectContextValue);
// End Context

export interface SelectProps extends Omit<FieldPropsWithIcons<any>, 'onChange'> {
  children: React.ReactNode;
  onChange: (value: any) => void;
  [key: `data-${string}`]: string;
  // defaults
  /** Set if placeholder should be visible in options; default false */
  showPlaceholderOption?: boolean;
  /** Set if placeholder in options should be interactable; default false */
  placeholderEnabled?: boolean;
  /** By default, icon right is the dropdown arrow */
  IconRight?: React.FC<React.SVGAttributes<SVGElement>>;
  /** can be used to show an autofill styling if desired; default false */
  autoFilled?: boolean;
}

const Select = ({
  name,
  value,
  onChange,
  severity,
  disabled,
  helperText,
  label,
  placeholder,
  required,
  validationText,
  IconLeft,
  children,
  IconRight = () => <IconChevronDown />,
  showPlaceholderOption = false,
  placeholderEnabled = false,
  autoFilled = false,
  ...dataAttributes
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip(),
      size({
        apply({ elements, rects }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${rects.reference.width}px`,
          });
        },
      }),
    ],
  });

  const elementsRef = useRef<Array<HTMLElement | null>>([]);
  const labelsRef = useRef<Array<string | null>>([]);

  const handleSelect = useCallback(
    (index: number | null, value?: string) => {
      setSelectedIndex(index);
      setIsOpen(false);
      if (index !== null) {
        if (typeof value !== 'undefined') {
          onChange(value);
        } else {
          onChange(labelsRef.current[index] ?? '');
        }
      }
    },
    [onChange],
  );

  // Set all floating contexts
  function handleTypeaheadMatch(index: number | null) {
    if (isOpen) {
      setActiveIndex(index);
    } else {
      handleSelect(index);
    }
  }
  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    activeIndex,
    selectedIndex,
    onMatch: handleTypeaheadMatch,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  // apply floating contexts
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    listNav,
    typeahead,
    click,
    dismiss,
    role,
  ]);

  const selectContext = useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, selectedIndex, getItemProps, handleSelect],
  );

  return (
    <>
      <TextField
        name={name}
        value={value ?? ''}
        severity={severity}
        disabled={disabled}
        helperText={helperText}
        label={label}
        placeholder={placeholder}
        required={required}
        validationText={validationText}
        IconRight={IconRight}
        IconLeft={IconLeft}
        readOnly
        autoFilled={autoFilled}
        isFocused={isOpen}
        ref={refs.setReference}
        {...dataAttributes}
        {...getReferenceProps()}
      />
      <SelectContext.Provider value={selectContext}>
        {isOpen && (
          <FloatingFocusManager context={context} modal={true}>
            <div ref={refs.setFloating} className={styles.dropdown} style={floatingStyles} {...getFloatingProps()}>
              <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                {showPlaceholderOption && placeholder && (
                  <SelectOption label={placeholder} value={''} disabled={!placeholderEnabled} />
                )}
                {children}
              </FloatingList>
            </div>
          </FloatingFocusManager>
        )}
      </SelectContext.Provider>
    </>
  );
};

interface SelectOptionProps {
  /** label to display, user can type to reach */
  label: string;
  /** value to return if user select; if not set will instead use label */
  value?: any;
  disabled?: boolean;
  [key: `data-${string}`]: string;
}

export const SelectOption = ({ label, value, disabled, ...htmlDataAttr }: SelectOptionProps) => {
  const { activeIndex, selectedIndex, getItemProps, handleSelect } = useContext(SelectContext);
  const { ref, index } = useListItem({ label: disabled ? null : label });

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  return (
    <button
      ref={ref}
      role="option"
      type="button"
      aria-selected={isActive && isSelected}
      tabIndex={isActive ? 0 : -1}
      className={cc([styles.selectOption, isActive && styles.isActive, isSelected && styles.isSelected])}
      {...getItemProps({
        onClick: () => handleSelect(index, value),
      })}
      disabled={disabled}
      {...htmlDataAttr}
    >
      {label}
    </button>
  );
};

export default Select;
